import React from 'react';
import './comment.css';

function Comment({ author, text, date }) {
    
  return (
    <div className='comment-container'>
        <p style={{fontSize:'18px',fontWeight:'400',fontFamily:"'Alata', sans-serif"}}>{author}</p>
        <p style={{fontSize:'12.5px',color:'rgba(0,0,0,.55)',fontFamily:"'Roboto Slab', serif"}}>{date}</p>
        <p style={{fontSize:'13.5px',fontFamily:"'Roboto Slab', serif",color:'rgba(0,0,0,.65)',textAlign:'justify'}}>{text}</p>
    </div>
  )
}

export default Comment