import React, { useEffect } from 'react';

function MarketsToday() {

    useEffect(()=>{
        var divElement = document.querySelector('.marketsToday');
        const scriptElement = document.createElement("script");
        scriptElement.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        scriptElement.async = true;
        scriptElement.innerHTML =`
        {
          "symbols": [
            {
              "description": "BSE TCS",
              "proName": "BSE:TCS"
            },
            {
              "description": "BSE SEBSEX",
              "proName": "INDEX:SENSEX"
            },
            {
              "description": "BSE RELIANCE",
              "proName": "BSE:RELIANCE"
            },
            {
              "description": "BSE INFY",
              "proName": "BSE:INFY"
            },
            {
              "description": "BSE HDFC",
              "proName": "BSE:HDFC"
            },
            {
              "description": "BSE HDFCBANK",
              "proName": "BSE:HDFCBANK"
            },
            {
              "description": "BSE ICICIBANK",
              "proName": "BSE:ICICIBANK"
            },
            {
              "description": "BSE TATASTEEL",
              "proName": "BSE:TATASTEEL"
            },
            {
              "description": "BSE HINDALCO",
              "proName": "BSE:HINDALCO"
            },
            {
              "description": "BSE ITC",
              "proName": "BSE:ITC"
            },
            {
              "description": "BSE SBIN",
              "proName": "BSE:SBIN"
            },
            {
              "description": "BSE ASIANPAINT",
              "proName": "BSE:ASIANPAINT"
            },
            {
              "description": "BSE BAJFINANCE",
              "proName": "BSE:BAJFINANCE"
            },
            {
              "description": "BSE MARUTI",
              "proName": "BSE:MARUTI"
            },
            {
              "description": "BSE BAJAJA_AUTO",
              "proName": "BSE:BAJAJ_AUTO"
            }
          ],
          "showSymbolLogo": true,
          "colorTheme": "light",
          "isTransparent": false,
          "displayMode": "adaptive",
          "locale": "in"
        }        
        `
        divElement.appendChild(scriptElement);

    },[])

  return (
    <div className="tradingview-widget-container marketsToday">
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/markets/" rel="noopener" target="_blank">
                <span className="blue-text">Markets Today</span>
            </a> by TradingView
        </div>
    </div>
  )
}

export default MarketsToday;