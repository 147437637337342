import React, { useEffect } from 'react';

function EconomicCalender() {
    useEffect(() => {
        var divElement = document.querySelector('.marketSummary');
        var marketSummary = document.querySelector('.market-summary');
        if ( window.innerWidth > 800 ) { 
          marketSummary.style.width='54%';
          marketSummary.style.height=(window.innerWidth*0.52)+'px';
        } else if ( window.innerWidth > 550 ) { 
          marketSummary.style.width='54%';
          marketSummary.style.height=(window.innerWidth*0.4)+'px';
        } else { 
          marketSummary.style.width='100%';
          marketSummary.style.height='500px';
        }
        const scriptElement = document.createElement("script");
        scriptElement.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
        scriptElement.async = true;
        scriptElement.innerHTML =`
        {
          "colorTheme": "light",
          "isTransparent": false,
          "width": "100%",
          "height": "100%",
          "locale": "in",
          "importanceFilter": "-1,0,1",
          "currencyFilter": "USD,CNY,EUR,FRF,DEM,INR,JPY,GBP"
        }        
        `
        divElement.appendChild(scriptElement);
        
    },[]);

  return (
    <div className='market-summary'>
      <div className="tradingview-widget-container marketSummary">
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
              <a href="https://in.tradingview.com/economic-calendar/" rel="noopener" target="_blank">
                  <span className="blue-text">Economic calendar</span>
              </a> by TradingView
          </div>
      </div>
    </div>
  );
}

export default EconomicCalender;