import React, { useEffect } from 'react';

function CryptoCurrency() {

  useEffect(()=>{
    var divElement = document.querySelector('.cryptoCurrency');
    var cryptoCurrency = document.querySelector('.crypto-currency');
    if ( window.innerWidth > 800 ) { 
        cryptoCurrency.style.width='100%';
        cryptoCurrency.style.height=(window.innerWidth*0.52)+'px';
    } else if ( window.innerWidth > 550 ) { 
        cryptoCurrency.style.width='100%';
        cryptoCurrency.style.height=(window.innerWidth*0.4)+'px';
    } else { 
        cryptoCurrency.style.width='100%';
        cryptoCurrency.style.height='500px';
    }
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
    scriptElement.async = true;
    scriptElement.innerHTML =`
    {
        "feedMode": "market",
        "market": "crypto",
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "regular",
        "width": "100%",
        "height": "100%",
        "locale": "in"
    }    
    `
    divElement.appendChild(scriptElement);

  },[])

  return (
    <div className='crypto-currency'>
      <div className="tradingview-widget-container cryptoCurrency">
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
              <a href="https://in.tradingview.com/markets/cryptocurrencies/key-events/" rel="noopener" target="_blank">
                  <span className="blue-text">Daily cryptocurrency news</span>
              </a> by TradingView
          </div>
      </div>
    </div>
  )
}

export default CryptoCurrency;