import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPost } from '../../stores/utils/thunks';

function EditPost() {
    const post = useSelector((state)=> state.posts.postById);
    const id = post.blog_id;
    const navigate = useNavigate();
    const [title, setTitle] = useState(post.blog_title);
    const [body, setMessage] = useState(post.blog_body);
    const [img, setImageURL] = useState(post.blog_img);
    const [author, setAuthor] = useState(post.blog_author);
    const [date, setDate] = useState(post.blog_date);

    const dispatch = useDispatch();

    function handleTitleChange(event){
        setTitle(event.target.value);
    }
    function handleMessageChange(event){
        setMessage(event.target.value);
    }
    function handleAuthorChange(event){
        setAuthor(event.target.value);
    }
    function handleDateChange(event){
        setDate(event.target.value);
    }
    function handleImageURLChange(event) {
        setImageURL(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const newPost = { id, title, body, img, author, date};
        dispatch(createPost(newPost)).then(()=>{
            navigate(`/blogs/singlePost/${id}`)
        });
    }

  return (
    <form className='createpost-container' onSubmit={handleSubmit}>
        <div className='inputBox'>
            <label htmlFor='title'>Blog Title* :</label>
            <input type='text' id='title' name='title' value={title} onChange={handleTitleChange} required/>
        </div>
        <div className='inputBox txtarea'>
            <label htmlFor='body'>Blog Content* :</label>
            <textarea type='text' id='body' name='body' value={body} onChange={handleMessageChange} required/>
        </div> 
        <div className='inputBox'>
            <label htmlFor='img'>Image URL* :</label>
            <input type='text' id='img' name='img' value={img} onChange={handleImageURLChange} required/>
        </div> 
        <div className='inputBox'>
            <label htmlFor='author'>Author* :</label>
            <input type='text' id='author' name='author' value={author} onChange={handleAuthorChange} required/>
        </div> 
        <div className='inputBox'>
            <label htmlFor='date'>Date* :</label>
            <input type='date' id='date' name='date' value={date} onChange={handleDateChange} required/>
        </div> 
        <input type='submit' value='Publish' className='create-post-btn'></input>
        <Link to={`/blogs/singlePost/${id}`} style={{color:'#eb080a'}}>Go Back</Link>     
    </form>
  )
}

export default EditPost