import React, { useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import './header.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addLinks } from '../../stores/reducers/posts';

function Header() {

    const dispatch = useDispatch();
    const activeLink = useSelector((state)=> state.posts.activelink)

    const[dropdown, setDropdown] = useState(false);
    const[analyzeDropdown, setAnalyzeDropdown] = useState(false);
    const[insightDropdown, setInsightDropdown] = useState(false);
    const[quantDropdown, setQuantDropdown] = useState(false);

    useEffect(()=>{
        const links = document.querySelectorAll('.nav-menu > ul > li > a');
        links.forEach((link) => {
            link.addEventListener('click',()=>handleLine(link));
        })

        if(activeLink === '/about'){
            handleLine(links[2])
            dispatch(addLinks(''))
        }
        if(activeLink === '/'){
            handleLine(links[1])
            dispatch(addLinks(''))
        }

        let links1;
        if(dropdown){
            links1 = document.querySelectorAll('.offering-menu a');
        }
        if(links1){
        links1.forEach((link)=>{
            link.addEventListener('click',()=>handleLine(document.querySelector('.offering')));
        })}

        function handleLine(linked){
            links.forEach(link =>{
                link.classList.remove('active');
            })
            linked.classList.add('active');
        }
        
        return () => {
            links.forEach(link => {
              link.removeEventListener('click', ()=>handleLine(link));
            });
            if(links1){
                links1.forEach((link)=>{
                link.removeEventListener('click',()=>handleLine(document.querySelector('.offering')));
            })}
        };
    },[dropdown,activeLink])

    const toggleMenu = () => {
        const navMenu = document.querySelector('.nav-menu');
        const menuToggle = document.querySelector('.menuToggle');
        menuToggle.classList.toggle('active');
        navMenu.classList.toggle('active');
        setAnalyzeDropdown(false);
        setInsightDropdown(false);
        setQuantDropdown(false);
        setDropdown(false);
    }

    const handleAnalyzeopen = () => {
        setDropdown(true);
        setAnalyzeDropdown(true);
    }
    const handleAnalyzeclose = () => {
        setDropdown(false);
        setAnalyzeDropdown(false);
    }

    const handleInsightopen = () => {
        setDropdown(true);
        setInsightDropdown(true);
    }
    const handleInsightclose = () => {
        setDropdown(false);
        setInsightDropdown(false);
    }

    const handleQuantopen = () => {
        setDropdown(true);
        setInsightDropdown(true);
        setQuantDropdown(true);;
    }
    const handleQuantclose = () => {
        setDropdown(false);
        setQuantDropdown(false);
        setInsightDropdown(false);
    }

    const handleDropdown = () => {
        setDropdown(!dropdown);
        setAnalyzeDropdown(false);
        setInsightDropdown(false);
        document.querySelector('.main-menu > li > .temp').classList.toggle('active');
    }

    const handleAnalyzeDropdown = () => {
        setDropdown(true);
        setAnalyzeDropdown(!analyzeDropdown);
        setInsightDropdown(false);
        document.querySelector('.offering-menu > li:nth-child(3) .temp').classList.remove('active');
        document.querySelector('.offering-menu > li:nth-child(2) .temp').classList.toggle('active');
    }

    const handleInsightDropdown = () => {
        setDropdown(true);
        setInsightDropdown(!insightDropdown);
        setAnalyzeDropdown(false);
        document.querySelector('.offering-menu > li:nth-child(2) .temp').classList.remove('active');
        document.querySelector('.offering-menu > li:nth-child(3) .temp').classList.toggle('active');
    }

    const handleQuantDropdown = () => {
        setDropdown(true);
        setQuantDropdown(!quantDropdown);
        // setAnalyzeDropdown(false);
        // document.querySelector('.offering-menu > li:nth-child(2) .temp').classList.remove('active');
        document.querySelector('.offering-sub-menu .temp').classList.toggle('active');
    }
    

  return (
    <>
        <nav className='navBar' id='navBar'>
            <Link to="/" className='navbar-logo'>
                <img src={logo} alt='logo' width='110px' height='40px'/>
            </Link>
            <div className="menuToggle" onClick={()=>toggleMenu()}></div>
            <div className='nav-menu'>
                <ul className='main-menu'>
                    <li><Link to='/' className='active' onClick={()=>toggleMenu()}>Home</Link></li>
                    <li>
                        <div className='temp'onClick={()=>handleDropdown()}></div>
                        <Link 
                        className='offering'
                        onMouseEnter={()=>setDropdown(true)} 
                        onMouseLeave={()=>setDropdown(false)}
                        onClick={()=>handleDropdown()}>
                            Offerings                            
                        </Link>
                        {dropdown &&
                        <ul className='offering-menu'>                            
                            <li><Link to='/offerings/markets' onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=>setDropdown(false)} onClick={()=>toggleMenu()}>Markets</Link></li>
                            <li>
                                <div className='temp' onClick={()=>handleAnalyzeDropdown()}></div>
                                <Link onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Analyze</Link>
                                {analyzeDropdown && 
                                <ul className='offering-sub-menu'>
                                    <li><Link to='/offerings/analyze/stock-performance-dashboard' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Stock Performance</Link></li>
                                    <li><Link to='/offerings/analyze/index-performance-dashboard' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Index Performance</Link></li>
                                    <li><Link to='/offerings/analyze/index-futures-snapshot' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Index Futures</Link></li>
                                    <li><Link to='/offerings/analyze/futures-build-up' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Futures Build Up</Link></li>
                                    <li><Link to='/offerings/analyze/options-snapshot' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Options Snapshot</Link></li>
                                    <li><Link to='/offerings/analyze/daily-screener' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Daily Screener</Link></li>
                                    <li><Link to='/offerings/analyze/futures-snapshot' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Futures Snapshot</Link></li>
                                    <li><Link to='/offerings/analyze/world-markets-snapshot' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>World Markets</Link></li>
                                    <li><Link to='/offerings/analyze/fibonacci-trade-levels' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>Fibonacci Trade Levels</Link></li>
                                    <li><Link to='/offerings/analyze/monte-carlo-simulation-trade-levels' onClick={()=>toggleMenu()} onMouseEnter={()=>handleAnalyzeopen(true)} onMouseLeave={()=>handleAnalyzeclose(false)}>MC Simulation-Trade Levels</Link></li>
                                </ul>}
                            </li>                            
                            <li>
                                <div className='temp' onClick={()=>handleInsightDropdown()}></div>
                                <Link onMouseEnter={()=>handleInsightopen(true)} onMouseLeave={()=>handleInsightclose(false)}>Insights</Link>
                                {insightDropdown && 
                                <ul className='offering-sub-menu' >
                                    <li><Link to='/offerings/insights/backtesting' onClick={()=>toggleMenu()} onMouseEnter={()=>handleInsightopen(true)} onMouseLeave={()=>handleInsightclose(false)}>Backtesting</Link></li>
                                    <li><Link to='/offerings/insights/simulation' onClick={()=>toggleMenu()} onMouseEnter={()=>handleInsightopen(true)} onMouseLeave={()=>handleInsightclose(false)}>Simulation</Link></li>
                                    <li><Link to='/offerings/insights/predict' onClick={()=>toggleMenu()} onMouseEnter={()=>handleInsightopen(true)} onMouseLeave={()=>handleInsightclose(false)}>Predict</Link></li>
                                    <li>
                                        <div className='temp' onClick={()=>handleQuantDropdown()}></div>
                                        <Link onMouseEnter={()=>handleQuantopen(true)} onMouseLeave={()=>handleQuantclose(false)} onClick={()=>handleQuantDropdown()}>Quant-Stats</Link>
                                        {quantDropdown && 
                                        <ul className='offering-sub-menu'>
                                            <li><Link to='/offerings/insights/quant-stats/banknifty-analysis' onClick={()=>toggleMenu()} onMouseEnter={()=>handleQuantopen(true)} onMouseLeave={()=>handleQuantclose(false)}>BankNifty Analysis</Link></li>
                                            <li><Link to='/offerings/insights/quant-stats/nifty-analysis' onClick={()=>toggleMenu()} onMouseEnter={()=>handleQuantopen(true)} onMouseLeave={()=>handleQuantclose(false)}>Nifty Analysis</Link></li>
                                            <li><Link to='/offerings/insights/quant-stats/portfolio-analysis' onClick={()=>toggleMenu()} onMouseEnter={()=>handleQuantopen(true)} onMouseLeave={()=>handleQuantclose(false)}>Portfolio Analyzer</Link></li>
                                            <li><Link to='/offerings/insights/quant-stats/nifty-benchmarking' onClick={()=>toggleMenu()} onMouseEnter={()=>handleQuantopen(true)} onMouseLeave={()=>handleQuantclose(false)}>Nifty Benchmarking</Link></li>
                                            <li><Link to='/offerings/insights/quant-stats/banknifty-benchmarking' onClick={()=>toggleMenu()} onMouseEnter={()=>handleQuantopen(true)} onMouseLeave={()=>handleQuantclose(false)}>BankNifty Benchmarking</Link></li>
                                        </ul>}
                                    </li>
                                </ul>}
                            </li>
                            <li><Link to='/offerings/algo-trade' onClick={()=>toggleMenu()} onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=>setDropdown(false)}>Algo-Trade</Link></li>
                            <li><Link to='/offerings/portfolio-management' onClick={()=>toggleMenu()} onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=>setDropdown(false)}>Portfolio Management</Link></li>
                            <li><Link to='/offerings/strategy-hub' onClick={()=>toggleMenu()} onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=>setDropdown(false)}>Strategy Hub</Link></li>
                        </ul>}
                    </li>
                    <li><Link to='/about' onClick={()=>toggleMenu()}>About Us</Link></li>
                    <li><Link to='/ourteam' onClick={()=>toggleMenu()}>Our Team</Link></li>
                </ul>
                <Link to='/blogs' className='blog' onClick={()=>toggleMenu()}>Blog</Link>
            </div>
        </nav>
    </>
  )
}

export default Header;