import React from 'react';
import './home.css';
import { Link, useLocation } from 'react-router-dom'
import algotrade from '../../images/algo-trade.jpg';
import analyze from '../../images/analyze.jpg';
import insight from '../../images/insight.jpg';
import portfolio from '../../images/portfolio.jpg';
import { useDispatch } from 'react-redux';
import { addLinks } from '../../stores/reducers/posts';

function Home() {
  const dispatch = useDispatch();
  return (
    <div className='home-container' id='topScreen'>
      <div className='backgroundImg'>
        <div className='textarea'>
          <p>tradecafe.io</p>
          <p>Power your trades with our <span>all-powerful platform.</span></p>
        </div>
      </div>
      <div className='about-us'>
        <p>We Speak Markets Fluently</p>
        <Link to='/about' onClick={()=>dispatch(addLinks('/about'))} className='btn'>About Us</Link>
      </div>
      <div className='page-conatiner'>
      <div className='repeat-container'>
        <div className='imgBx'>
          <img src={algotrade} alt='algo-trade'/>
        </div>
        <div className='text-Content'>
          <p>ALGO TRADING</p>
          <p>Take emotions out of trade.</p>
          <p>Our company is offering retail investors access to powerful algorithms for efficient, 
            emotion-free trading. These algorithms have been carefully selected and tested for various 
            market conditions. Available as a service or platform, with easy broker integration. 
            Join the trend of algorithmic trading and improve your performance.</p>
            <Link to='/offerings/markets' onClick={()=>dispatch(addLinks('/'))} className='btn'>Learn More</Link>
        </div>
      </div>
      <div className='repeat-container column-reverse'>
        <div className='text-Content'>
          <p>ANALYZE</p>
          <p>All in one sleek dashboard</p>
          <p>Analyze is a comprehensive toolkit that provides a variety of resources to help you make 
            informed and strategic trading and investing decisions. Its information-rich dashboards 
            offer a wealth of data in various dimensions, giving you a deeper understanding of the 
            markets. These tools are essential for making data-driven decisions that can lead to success 
            in today's markets, whether you're a beginner or an experienced trader.</p>
            <Link to='/offerings/analyze' onClick={()=>dispatch(addLinks('/'))} className='btn'>Learn More</Link>
        </div>
        <div className='imgBx'>
          <img src={analyze} alt='analyze'/>
        </div>
      </div>
      <div className='repeat-container last-insight'>
        <div className='imgBx'>
          <img src={insight} alt='insight'/>
        </div>
        <div className='text-Content'>
          <p>INSIGHTS</p>
          <p>Backtest | Simulate | Predict | Quants</p>
          <p>Improve your trading performance with our platform's resources for discovering new trends, 
            market insights, and strategies. Stay ahead of the curve with constantly updated tools and 
            information. Beginner or experienced trader, our platform helps you make informed decisions 
            for success in the markets. Sign up now and start improving your trading performance</p>
            <Link to='/offerings/insights' onClick={()=>dispatch(addLinks('/'))} className='btn'>Learn More</Link>
        </div>
      </div>
      </div>
      <div className='portfolio'>
        <h4>PORTFOLIO MANAGEMENT</h4>
        <p>Our company specializes in maximizing wealth through the use of expertly-managed portfolios. 
          We carefully select and manage a diverse range of investments to help our clients achieve 
          their specific financial objectives and reach their financial goals. With a thorough 
          understanding of financial markets and investment options, we strive to maximize returns and 
          minimize drawdowns for our portfolio holders. Trust us to help you achieve financial success.
        </p>
        <div className='imageBox'>
          <img src={portfolio} alt='portfolio'/>
        </div>
      </div>
      <div className='contact'>
        <div className='contact-details'>
          <div className='contact-heading'>Contact</div>
          <p>Ready to invest? Give Tradecafe.io a call, and we will get you trading today.</p>
          <div>
            <h5>Email</h5>
            <p>support@tradecafe.io</p>
          </div>
          <div>
            <h5>Phone</h5>
            <p>9989400009</p>
          </div>
        </div>
        <div className='contact-form'>
          <div className="row100">
            <div className="col">
              <div className="inputBox">
                <span className="text">First Name *</span>
                <input type="text" name="fn" required id="fn"/>
              </div>
            </div>
            <div className="col">
              <div className="inputBox">
                <span className="text">Last Name *</span>
                <input type="text" name="ln" required id="ln"/>
              </div>
            </div>
          </div>
          <div className="row100">
            <div className="col">
              <div className="inputBox">
                <span className="text">Email *</span>
                <input type="email" name="email" required id="fn"/>
              </div>
            </div>
          </div>
          <div className="row100">
            <div className="col">
              <div className="inputBox txtarea">
                <span className="text">Message *</span>
                <textarea name="msg" required id="msg"/>                
              </div>
            </div>
          </div>
          <Link className='submit-btn'>Submit</Link>
        </div>
      </div>
    </div>
  )
}

export default Home;