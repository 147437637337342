import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './singlePost.css';
import createPost from '../../images/createPost.png';
import { useSelector, useDispatch } from 'react-redux';
import { createComment, fetchPostById, fetchPosts } from '../../stores/utils/thunks';
import Comment from './comment';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';


function SinglePost() {
    const [prev, setPrev] = useState(true);
    const [next, setNext] = useState(true);
    const [commentState, setCommentState] = useState(false);
    const [loginState, setLoginState] = useState(false);
    const post = useSelector((state)=> state.posts.postById);
    const end = useSelector((state)=> state.posts.end);
    const comments = useSelector((state)=> state.posts.postById.comments);
    const dispatch = useDispatch();
    let params = useParams();

    const [author, setAuthor] = useState('');
    const [body, setBody] = useState('');

    function handleAuthorChange(event){
      setAuthor(event.target.value);
    }
    function handleBodyChange(event){
      setBody(event.target.value);
    }
    async function handleSubmit(event) {
      event.preventDefault();
      const newComment = { author, body};
      dispatch(createComment({id:params.id,newComment:newComment})).then(()=>{setCommentState(!commentState)});
      setAuthor('');
      setBody('');
    }

    useEffect(()=>{
      dispatch(fetchPosts());
      dispatch(fetchPostById(params.id));
    },[params.id,commentState])

    useEffect(()=>{
        if(post.length !== 0 && post.blog_id === 1){
          setPrev(false);
        }else{
          setPrev(true)
        }
        if(post.length !== 0 && post.blog_id === end){
          setNext(false);
        }else{
          setNext(true)
        }
    },[post.blog_id])


  return (
    <div className='singlepost-conatiner'>
        <div className='singlepost-info'>
            <div className='singlepost-head'>
                <Link to='/blogs'>Go Back</Link>
                <p className='singlepost-auth-date'>{post.blog_author} | {post.blog_date}</p>
                <Link to={`/blogs/editPost/${params.id}`}><img src={createPost} alt='create'/></Link>
            </div>
            <p className='singlepost-title'>{post.blog_title}</p>
            <div className='singlepost-imgbx'>
                <img src={post.blog_img} alt='singlepost'/>
            </div>
        </div>
        <div className='singlepost-desc-container'>
            <p className='singlepost-desc'>
                {post.blog_body}
            </p>
        </div>
        <div className='older-newer'>
            {prev ? 
            <Link to={`/blogs/singlePost/${post.blog_id - 1}`} style={{left:'0'}}>&#8592;Older Post</Link> 
            : null}
            {next ? 
            <Link to={`/blogs/singlePost/${post.blog_id + 1}`} style={{right:'0'}}>Newer Post &#8594;</Link> 
            : null}
        </div>

        <div className='comment-outer-container'>
          <h1 style={{borderBottom:'2px solid rgba(0,0,0,.3)',padding:'20px 0px'}}>{comments ? comments.length : 0} Comment(s)</h1>
          {comments && comments.map((comment, index) => (
            <Comment key={index} author={comment.comment_author} text={comment.comment_body} date={comment.comment_date}/>
          ))}
          <div style={{padding:'10px'}}>
            <h3 style={{paddingTop:'50px'}}>Add a Comment</h3>
            <form className='createcomment-container' onSubmit={handleSubmit}>
              <div className='inputBox txtarea'>
                  <label htmlFor='body'>Comment* :</label>
                  <textarea type='text' id='body' maxLength={200} value={body} onChange={handleBodyChange} required/>
              </div>  
              <div className='inputBox'>
                  <label htmlFor='author'>Author* :</label>
                  <input type='text' id='author' value={author} onChange={handleAuthorChange} required/>
              </div>
              {loginState ? 
                <input type='submit' value='Post Comment' className='create-post-btn'></input> : 
                <LoginSocialFacebook
                  appId='207210142154406'
                  onResolve={(response)=>{
                    setLoginState(true)
                    console.log(response.data)
                  }}
                  onReject={(error)=>{
                    console.log(error)
                  }}
                  className='login-button'
                >
                  <div className='create-post-btn'>Post Comment</div>
                  <p style={{fontSize:'14px',color:'#01a7a3',fontWeight:'bold',marginTop:'5px'}}>Login to Facebook to post comment</p>
                </LoginSocialFacebook>
              }
            </form>
          </div>
        </div>
    </div>
  )
}

export default SinglePost