import React from 'react';
import './insight-submenu.css';
import comingsoonLogo from '../../../images/coming-soon-logo.png';
import predict from '../../../images/predict.png';

function Predict() {
  return (
    <div className='insight-submenu-container' id='topScreen'>
        <div className='submenu-heading'>
            <img src={comingsoonLogo} alt='coming-soon-logo'/>
            <p>Predict</p>
        </div>
        <div className='submneu-content'>
            <p>At TradeCafe, we offer advanced machine learning models to help predict the stock market 
                trends and provide valuable insights for our clients. Our team of data scientists have 
                developed cutting-edge algorithms that are trained on vast amounts of historical stock 
                data, which enables us to make accurate and reliable predictions on future market trends.
                <br/><br/>
                <img src={predict} alt='predict' className='predict'/>
                Our stock market prediction model utilizes a wide range of advanced machine learning 
                techniques such as deep neural networks, ensemble methods, and gradient boosting 
                algorithms. These methods allow us to analyze and identify patterns in historical stock 
                data, which we can then use to make predictions on future market movements.<br/><br/>  
                We understand that predicting the stock market is a complex and challenging task. Our 
                team has developed models that can take into account a wide range of factors, such as 
                company financial data, macroeconomic indicators, news sentiment analysis, and social 
                media trends. This allows us to provide our clients with a holistic view of the market, 
                enabling them to make informed investment decisions.<br/><br/>  
                Our models are continuously trained and refined using the latest stock market data, 
                ensuring that our predictions are always up-to-date and accurate. We also provide our 
                clients with detailed reports on our findings, including visualizations and 
                recommendations based on our analysis.<br/><br/> 
                In summary, at TradeCafe, we offer advanced machine learning-based stock market 
                prediction models that provide our clients with valuable insights into market trends. 
                Our team of data scientists works tirelessly to ensure that our models are accurate, 
                reliable, and up-to-date, allowing our clients to make informed investment decisions. 
                Contact us today to learn more about how our machine learning models can help you 
                navigate the complex world of the stock market.
            </p>
        </div>
    </div>
  )
}

export default Predict