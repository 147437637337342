import React from 'react';
import logo from '../../images/footer-logo.png';
import './footer.css';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
        <div className='logo-address'>
            <img src={logo} alt='logo'/>
            <span>#381 , APR Pranav</span>
            <span>Bachupally</span>
            <span>Hyderabad</span>
        </div>
        <div><Link>About Us</Link></div>       
        <div className='offerings'>
            <h6>Offerings</h6>
            <p>Back Testing</p>
            <p>Algo Trading</p>
            <p>Strategy Hub</p>
        </div>
    </div>
  )
}

export default Footer;