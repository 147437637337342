import {createSlice} from '@reduxjs/toolkit';
import { fetchNextPosts, fetchPostById, fetchPosts, fetchPrevPosts } from '../utils/thunks';

export const postsSlice = createSlice({
    name:'posts',
    initialState:{
        posts:[],
        postById:[],
        activelink:'',
    },
    reducers:{
        addLinks : (state,action)=>{
            state.activelink = action.payload;
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchPosts.fulfilled,(state,action)=>{
            state.posts = action.payload.posts;
            state.end = action.payload.end;
        })
        .addCase(fetchPostById.fulfilled,(state,action)=>{
            state.postById = action.payload;
        })
        .addCase(fetchPrevPosts.fulfilled,(state,action)=>{
            state.posts = action.payload;
        })
        .addCase(fetchNextPosts.fulfilled,(state,action)=>{
            state.posts = action.payload;
        })
    }
})

export const { addLinks } = postsSlice.actions;
export default postsSlice.reducer;