import React from 'react';
import { Link } from 'react-router-dom';
import './blog.css';


function Blog({id, author, body, date, img, title}) {
  return (
    <div className='blog-container'>
        <div className='blog-imgbx'>
            <img src={img} alt='blog'/>
        </div>
        <p className='post-title'>{title}</p>
        <p className='auth-date'>{author} | {date}</p>
        <p className='post-descr'>
            {body}
        </p>
        <Link to={`/blogs/singlePost/${id}`}>Read More &#8594;</Link>
    </div>
  )
}

export default Blog;