// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
        var tcsStock = document.querySelector('.tcs-stock');
        if ( window.innerWidth > 800 ) { 
            tcsStock.style.width='100%';
            tcsStock.style.height=(window.innerWidth*0.45)+'px';
        } else if ( window.innerWidth > 550 ) { 
            tcsStock.style.width='100%';
            tcsStock.style.height=(window.innerWidth*0.55)+'px';
        } else { 
          tcsStock.style.width='100%';
            tcsStock.style.height='400px';
        }
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_2ec0d') && 'TradingView' in window) {
          new window.TradingView.widget({
            width: "100%",
            height: "100%",
            // autosize: true,
            symbol: "BSE:TCS",
            interval: "D",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "in",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            details: true,
            calendar: true,
            container_id: "tradingview_2ec0d"
          });
        }
      }
    },
    []
  );

  return (
    <div className='tcs-stock'>
        <div className='tradingview-widget-container'>
        <div id='tradingview_2ec0d' />
        <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/symbols/BSE-TCS/" rel="noopener" target="_blank"><span className="blue-text">TCS stock chart</span></a> by TradingView
        </div>
        </div>
    </div>
  );
}