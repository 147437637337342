import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './blogPage.css';
import Blog from './blog';
import createPost from '../../images/createPost.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNextPosts, fetchPosts, fetchPrevPosts } from '../../stores/utils/thunks';

function BlogPage() {
  const [prev, setPrev] = useState(true);
  const [next, setNext] = useState(false);
  const posts = useSelector((state)=> state.posts.posts);
  const end = useSelector((state)=> state.posts.end);
  const blogId = posts.length !== 0 && posts[0].blog_id;

  useEffect(()=>{
    if(posts.length !== 0 && posts[posts.length-1].blog_id === 1){
      setPrev(false);
    }
    if(posts.length !== 0 && posts[0].blog_id === end){
      setNext(false);
    }
  },[blogId])

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(fetchPosts());
  },[])

  const handlePrev = () =>{
    if(posts.length !== 0 && posts[posts.length-1].blog_id !== 1){
      dispatch(fetchPrevPosts(posts[posts.length-1].blog_id))
      setNext(true);
    }
  }

  const handleNext = () => {
    if(posts.length !== 0){
      dispatch(fetchNextPosts(posts[0].blog_id + 1));
      setPrev(true);
    }
  }
  
  return (
    <div className='blog-outer-conatiner'>
      <div className='blog-inner-container'>
        {posts ? 
          posts.map((post)=>{
            return(
              <Blog key={post.blog_id} id={post.blog_id} author={post.blog_author} body={post.blog_body} date={post.blog_date} 
              img={post.blog_img} title={post.blog_title}/>
            )
          })
          :null}
      </div>
      <div className='blog-create-container'>
        {prev ? <input type='submit' value='&#8592;' onClick={handlePrev} className='prev-next-btn'/> 
        : <input type='submit' value='&#8592;' disabled className='prev-next-btn disabled'/>}
        <Link to='/blogs/createPost'><img src={createPost} alt='create'/></Link>
        {next ? <input type='submit' value='&#8594;' onClick={handleNext} className='prev-next-btn'/>
        : <input type='submit' value='&#8594;' disabled className='prev-next-btn disabled'/>}       
      </div>     
    </div>
  )
}

export default BlogPage;