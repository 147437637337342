import './App.css';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import { FaChevronCircleUp } from "react-icons/fa";
import Home from './screens/home/home';
import OptSnapshot from './screens/analyze/analyze-submenus/optSnapshot';
import SpDashboard from './screens/analyze/analyze-submenus/spDashboard';
import IpDashboard from './screens/analyze/analyze-submenus/ipDashboard';
import IfSnapshot from './screens/analyze/analyze-submenus/ifSnapshot';
import FutBuildUp from './screens/analyze/analyze-submenus/futBuildUp';
import DailyScreener from './screens/analyze/analyze-submenus/dailyScreener';
import FuturesSnapshot from './screens/analyze/analyze-submenus/futuresSnapshot';
import WmSnapshot from './screens/analyze/analyze-submenus/wmSnapshot';
import FibTradeLevels from './screens/analyze/analyze-submenus/fibTradeLevels';
import McsTradeLevels from './screens/analyze/analyze-submenus/mcsTradeLevels';
import Market from './screens/markets/market';
import Backtesting from './screens/insights/insight-submenus/backtesting';
import Simulation from './screens/insights/insight-submenus/simulation';
import Predict from './screens/insights/insight-submenus/predict';
import Algotrade from './screens/algotrade';
import PortfolioManagement from './screens/portfolioManagement';
import StrategyHub from './screens/strategyHub';
import BankNiftyAnalysis from './screens/quantStats/bankNiftyAnalysis';
import NiftyAnalysis from './screens/quantStats/niftyAnalysis';
import PortfolioAnalyzer from './screens/quantStats/portfolioAnalyzer';
import NiftyBenchmarking from './screens/quantStats/niftyBenchmarking';
import BankNiftyBenchmarking from './screens/quantStats/bankNiftyBenchmarking';
import About from './screens/about/about';
import OurTeam from './screens/ourTeam/ourTeam';
import BlogPage from './screens/blog/blogPage';
import SinglePost from './screens/blog/singlePost';
import CreatePost from './screens/blog/createPost';
import EditPost from './screens/blog/editPost';

function App() {
  return (
   <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>} exact/>
        <Route path='/about' element={<About/>}/>
        <Route path='/ourteam' element={<OurTeam/>}/>
        <Route path='/blogs' element={<BlogPage/>}/>
        <Route path='/blogs/singlePost/:id' element={<SinglePost/>}/>
        <Route path='/blogs/createPost' element={<CreatePost/>}/>
        <Route path='/blogs/editPost/:id' element={<EditPost/>}/>
        <Route path='/offerings/markets' element={<Market/>}/>
        <Route path='/offerings/algo-trade' element={<Algotrade/>}/>
        <Route path='/offerings/portfolio-management' element={<PortfolioManagement/>}/>
        <Route path='/offerings/strategy-hub' element={<StrategyHub/>}/>
        <Route path='/offerings/analyze/stock-performance-dashboard' element={<SpDashboard/>}/>
        <Route path='/offerings/analyze/index-performance-dashboard' element={<IpDashboard/>}/>
        <Route path='/offerings/analyze/index-futures-snapshot' element={<IfSnapshot/>}/>
        <Route path='/offerings/analyze/futures-build-up' element={<FutBuildUp/>}/>
        <Route path='/offerings/analyze/options-snapshot' element={<OptSnapshot/>}/>
        <Route path='/offerings/analyze/daily-screener' element={<DailyScreener/>}/>
        <Route path='/offerings/analyze/futures-snapshot' element={<FuturesSnapshot/>}/>
        <Route path='/offerings/analyze/world-markets-snapshot' element={<WmSnapshot/>}/>
        <Route path='/offerings/analyze/fibonacci-trade-levels' element={<FibTradeLevels/>}/>
        <Route path='/offerings/analyze/monte-carlo-simulation-trade-levels' element={<McsTradeLevels/>}/>
        <Route path='/offerings/insights/backtesting' element={<Backtesting/>}/>
        <Route path='/offerings/insights/simulation' element={<Simulation/>}/>
        <Route path='/offerings/insights/predict' element={<Predict/>}/>
        <Route path='/offerings/insights/quant-stats/banknifty-analysis' element={<BankNiftyAnalysis/>}/>
        <Route path='/offerings/insights/quant-stats/nifty-analysis' element={<NiftyAnalysis/>}/>
        <Route path='/offerings/insights/quant-stats/portfolio-analysis' element={<PortfolioAnalyzer/>}/>
        <Route path='/offerings/insights/quant-stats/nifty-benchmarking' element={<NiftyBenchmarking/>}/>
        <Route path='/offerings/insights/quant-stats/banknifty-benchmarking' element={<BankNiftyBenchmarking/>}/>
      </Routes>
      <a href='#topScreen' className='topBar'>
        <IconContext.Provider value={{size: "20px", color: "#fff"}}><FaChevronCircleUp/></IconContext.Provider>
      </a>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
