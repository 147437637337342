import React, { useEffect } from 'react';

function StocksToday() {

  useEffect(()=>{
    var divElement = document.querySelector('.worldMarkets');
    var worldMarkets = document.querySelector('.world-markets');
    if ( window.innerWidth > 800 ) { 
      worldMarkets.style.width='45%';
      worldMarkets.style.height=(window.innerWidth*0.52)+'px';
    } else if ( window.innerWidth > 550 ) { 
      worldMarkets.style.width='45%';
      worldMarkets.style.height=(window.innerWidth*0.4)+'px';
    } else { 
      worldMarkets.style.width='100%';
      worldMarkets.style.height='500px';
    }
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" ;
    scriptElement.async = true;
    scriptElement.innerHTML =`
    {
      "colorTheme": "light",
      "dateRange": "12M",
      "exchange": "BSE",
      "showChart": true,
      "locale": "in",
      "largeChartUrl": "",
      "isTransparent": false,
      "showSymbolLogo": false,
      "showFloatingTooltip": false,
      "width": "100%",
      "height": "100%",
      "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
      "plotLineColorFalling": "rgba(41, 98, 255, 1)",
      "gridLineColor": "rgba(240, 243, 250, 0)",
      "scaleFontColor": "rgba(106, 109, 120, 1)",
      "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
      "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
      "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
      "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
      "symbolActiveColor": "rgba(41, 98, 255, 0.12)"
    }
    
    `
    divElement.appendChild(scriptElement);
    
  },[])

  return (
    <div className='world-markets'>
      <div className="tradingview-widget-container worldMarkets">
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
              <a href="https://in.tradingview.com/markets/stocks-india/" rel="noopener" target="_blank">
                  <span className="blue-text">Stocks today</span>
              </a> by TradingView
          </div>
      </div>
    </div>
  )
}

export default StocksToday;