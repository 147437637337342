import React, { useEffect } from 'react';

function StockScreener() {

    useEffect(()=>{
          var divElement = document.querySelector('.stockScreener');
          var stockScreener = document.querySelector('.stock-screener');
          if ( window.innerWidth > 800 ) { 
            stockScreener.style.width='100%';
            stockScreener.style.height=(window.innerWidth*0.45)+'px';
          } else if ( window.innerWidth > 550 ) { 
            stockScreener.style.width='100%';
            stockScreener.style.height=(window.innerWidth*0.6)+'px';
          } else { 
            stockScreener.style.width='100%';
            stockScreener.style.height='400px';
          }       

          const scriptElement = document.createElement("script");
          scriptElement.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
          scriptElement.async = true;
          scriptElement.innerHTML =`
          {
            "width": "100%",
            "height": "100%",
            "defaultColumn": "overview",
            "defaultScreen": "most_capitalized",
            "market": "india",
            "showToolbar": true,
            "colorTheme": "light",
            "locale": "in"
          }
          `
          divElement.appendChild(scriptElement);
          
      },[])

  return (
    <div className='stock-screener'>
        <div className="tradingview-widget-container stockScreener">
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
                <a href="https://in.tradingview.com/screener/" rel="noopener" target="_blank">
                    <span className="blue-text">Stock screener</span>
                </a> by TradingView
            </div>
        </div>
    </div>
  )
}

export default StockScreener