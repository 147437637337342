import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
const URL_SERV = "http://127.0.0.1:5000";

export const fetchPosts = createAsyncThunk(
    'posts/fetchPosts',
    async()=>{
        try{
            const response = await axios.get(`${URL_SERV}/blogs`)
            return{
                posts : response.data,
                end : response.data[0].blog_id
            }
        }catch(error){
            throw error;
        }
    }
)

export const fetchNextPosts = createAsyncThunk(
    'posts/fetchNextPosts',
    async(id)=>{
        try{
            const response = await axios.get(`${URL_SERV}/blogs/next/${id}`)
            return response.data;
        }catch(error){
            throw error;
        }
    }
)

export const fetchPrevPosts = createAsyncThunk(
    'posts/fetchPrevPosts',
    async(id)=>{
        try{
            const response = await axios.get(`${URL_SERV}/blogs/prev/${id}`)
            return response.data;
        }catch(error){
            throw error;
        }
    }
)

export const fetchPostById = createAsyncThunk(
    'posts/fetchPostById',
    async(id)=>{
        try{
            const response = await axios.get(`${URL_SERV}/blog/${id}`)
            return response.data;
        }catch(error){
            throw error;
        }
    }
)

export const createPost = createAsyncThunk(
    'posts/createPost',
    async(newPost)=>{
        try{
            await axios.post(`${URL_SERV}/blogs`,newPost);
        }catch(error){
            throw error;
        }
    }
)

export const createComment = createAsyncThunk(
    'posts/createComment',
    async({id,newComment})=>{
        try{
            await axios.post(`${URL_SERV}/blog/${id}/comment`,newComment)
        }catch(error){
            throw error;
        }
    }
)