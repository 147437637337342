import React from 'react'

function BankNiftyBenchmarking() {
  // const imgSrc = require('../../images/niftyBenchmarking.jpg')
  const imgSrc = process.env.PUBLIC_URL + '/publicImages/niftyBenchmarking.jpg';
  return (
    <div className='quant-container' id='topScreen'>
      <img src={imgSrc} alt='nifty' width='100%' height='100%'/>
    </div>
  )
}

export default BankNiftyBenchmarking