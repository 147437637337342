import React from 'react';
const pdfFile = require('./NIFTY.pdf');

function NiftyAnalysis() {
  return (
    <div className='quant-container' id='topScreen'>
      {<object data='https://pdfers.s3.amazonaws.com/NIFTY.pdf' type="application/pdf" width="100%" height="100%" >
        <p>Alternative text - include a link <a href='https://pdfers.s3.amazonaws.com/NIFTY.pdf'>to the PDF!</a></p>
      </object>}
    </div>
  )
}

// import React from 'react';
// //import { Document, Page } from 'react-pdf';
// import { Document, Page, pdfjs } from "react-pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// //import './NiftyAnalysis.css'; // You may need to adjust the path and filename

// function NiftyAnalysis() {
//   const pdfFile = '/publicImages/sample.pdf'; // Replace with the correct path to your PDF file

//   return (
//     <div className='quant-container' id='topScreen'>
//       <Document file={pdfFile}>
//         <Page pageNumber={1} width={600} /> {/* You can change the page number and width */}
//       </Document>
//     </div>
//   );
// }

export default NiftyAnalysis;