import React from 'react';
import './insights/insight-submenus/insight-submenu.css';
import comingsoonLogo from '../images/coming-soon-logo.png';
import strategy from '../images/strategy.png';

function StrategyHub() {
  return (
    <div className='insight-submenu-container' id='topScreen'>
        <div className='submenu-heading'>
            <img src={comingsoonLogo} alt='coming-soon-logo'/>
            <p>Strategy Hub</p>
        </div>
        <div className='submneu-content .offering-content'>
            <p>Welcome to Tradecafe, where we offer access to a strategy repository for stock trading 
                and investing. Our repository is a carefully curated collection of trading and 
                investment strategies developed by experts to help clients make informed investment 
                decisions.<br/><br/>
                Our repository features a wide range of strategies that have been specifically designed 
                to cater to different investment objectives, risk profiles, and market conditions. We 
                source our strategies from both in-house and external partners with a proven track 
                record of success.
                <img src={strategy} alt='strategy' className='simulation'/>
                <br/><br/>
                Each strategy in our repository includes detailed information on past performance, 
                risk-reward ratio, entry and exit points, holding period, and potential risks or 
                challenges. Clients can subscribe to the strategies that fit their risk profile and 
                investment objectives, enabling them to make informed investment decisions based on 
                their individual needs.<br/><br/>  
                Our strategies are periodically backtested to validate their performance and refined to 
                optimize their performance and ensure that clients receive the best possible returns. 
                This enables clients to stay ahead of the curve and capitalize on new investment 
                opportunities as they arise.<br/><br/>  
                We offer access to our strategy repository as a standalone service or as part of a 
                broader trading or investment offering. By providing clients with access to our strategy 
                repository, we can help them stay up-to-date on the latest investment trends, best 
                practices, and proven investment strategies. This enables clients to improve their 
                investment performance and achieve their financial objectives with confidence.<br/><br/> 
                Choose Tradecafe for access to our expertly curated strategy repository and take your 
                investment performance to the next level.
            </p>
        </div>
    </div>
  )
}

export default StrategyHub