import React from 'react';
import './insight-submenu.css';
import comingsoonLogo from '../../../images/coming-soon-logo.png';
import backtesting from '../../../images/backtesting.png';

function Backtesting() {
  return (
    <div className='insight-submenu-container' id='topScreen'>
        <div className='submenu-heading'>
            <img src={comingsoonLogo} alt='coming-soon-logo'/>
            <p>Backtest</p>
        </div>
        <div className='submneu-content'>
            <img src={backtesting} alt='backtesting' className='backtesting'/>
            <p>At TradeCafe, we understand the importance of backtesting and walk-forward optimization 
                in the development of a successful trading and investment strategy. That's why we offer 
                our clients the expertise of our seasoned professionals to help them navigate this 
                crucial process.<br/><br/> Our team works closely with clients to identify the key components of 
                their strategy, including the appropriate time frame, relevant indicators, and entry and 
                exit rules. We then use our advanced tools and market data to simulate the strategy's 
                performance over historical periods.<br/><br/>  But we don't stop there. At TradeCafe, we recognize 
                that markets are constantly changing, and what worked in the past may not necessarily 
                work in the future. That's why we also perform walk-forward optimization to ensure the 
                strategy remains effective over time.<br/><br/>  Our experts divide the historical data into smaller 
                periods and simulate the strategy on a rolling basis, identifying potential areas for 
                improvement and adjusting the approach as necessary. The result is a comprehensive 
                performance report that gives our clients valuable insights for future decision-making. 
                <br/><br/> At TradeCafe, we are dedicated to helping our clients develop and refine their trading 
                and investment strategies. Contact us today to learn more about how we can help you 
                achieve your financial goals.
            </p>
        </div>
    </div>
  )
}

export default Backtesting