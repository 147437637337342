import React,{ useState, useEffect } from 'react';
import './quant.css';

function BankNiftyAnalysis() {
    const filePath = process.env.PUBLIC_URL + '/publicImages/niftyAnalysis.html';
    const [htmlContent, setHtmlContent] = useState('');
    useEffect(() => {
      async function fetchHtmlFile() {
        // '../../../publicImages/niftyAnalysis.html'
        const response = await fetch(filePath);
        // const response = await fetch('/niftyAnalysis.html');
        const html = await response.text();
        setHtmlContent(html);
      }
      fetchHtmlFile();
    }, []);
  
    return (
        <div className='quant-container' id='topScreen'>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
}

export default BankNiftyAnalysis