import React from 'react';
import './insights/insight-submenus/insight-submenu.css';
import comingsoonLogo from '../images/coming-soon-logo.png';
import algotrade from '../images/algotrade.png';

function Algotrade() {
  return (
    <div className='insight-submenu-container' id='topScreen'>
        <div className='submenu-heading'>
            <img src={comingsoonLogo} alt='coming-soon-logo'/>
            <p>Algo Trading</p>
        </div>
        <div className='submneu-content .offering-content'>
            <p>TradeCafe is proud to be at the forefront of the financial industry by offering the 
                future of automated trading with our cutting-edge algo trading technology. Our team of 
                expert data scientists and traders, along with our state-of-the-art algorithms, provides 
                reliable and profitable automated trading solutions for individuals and institutions 
                alike.
                <img src={algotrade} alt='algotrade' className='predict'/>
                <br/><br/>
                With our proprietary software utilizing advanced machine learning techniques, we are 
                able to analyze vast amounts of data and make informed trading decisions in real-time. 
                You can trust us to deliver superior returns and take your trading to the next level 
                with our world-class algo trading services.<br/><br/>
                Our commitment to seamless integration with multiple exchanges is unparalleled. We know 
                that accessing the latest and most profitable trading opportunities is essential to 
                maximizing your profits, so we have made it a top priority to integrate with as many 
                exchanges as possible. Our flexible and customizable platform allows you to create 
                trading strategies that align perfectly with your unique needs and goals.<br/><br/>  
                We are also fully integrated with top brokers and continue to add more as we go along. 
                Our onboarding process is quick and easy, so you can start machine trading with us in no 
                time. Join us today and experience the full potential of algo trading.
            </p>
        </div>
    </div>
  )
}

export default Algotrade