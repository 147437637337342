import React from 'react';
import './about.css';
import about from '../../images/about-us.jpg';

function About() {
  return (
    <div className='about-container' id='topScreen'>
        <div className='about-contentbx'>
            <p>We specialize in helping Retail investors get their toes wet in the trade market.</p>
            <p>
                <span>Welcome to tradecafe!!</span><br/><br/>
                We are a team of professionals committed to helping our clients achieve their long-term 
                financial goals. With over 22 years of experience in the IT industry working in different
                domains , primarily into BFSI, we have the knowledge and expertise to provide expert 
                guidance and personalised investment strategies.<br/><br/>
                Our company was founded in 2020 with the goal of providing high-quality financial 
                services to individuals, families, and small businesses. We believe in building strong, 
                long-term relationships with our clients and working with them to create a financial 
                plan that meets their unique needs and goals.<br/><br/>
                Our team is dedicated to staying up-to-date on the latest market trends and best 
                practices. We are committed to transparency and honesty, and always put our clients' 
                best interests first.<br/><br/>
                Thank you for choosing tradecafe. We look forward to working with you and helping you 
                succeed financially.
            </p>
            <p>-</p>
            <p>Sam Thota<br/><span>Founder</span></p>
        </div>
        <div className='about-imgbx'>
            <img src={about} alt='about'/>
        </div>
    </div>
  )
}

export default About