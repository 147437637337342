import React from 'react';
import './ourTeam.css';
import samthota from '../../images/samthota.png';
import srinithota from '../../images/srinithota.png';

function OurTeam() {
  return (
    <div className='ourteam-container' id='topScreen'>
        <div className='member-container'>
            <div className='member-imgbx'>
                <img src={samthota} alt='samthota'/>
            </div>
            <div className='member-content'>
                <p>Sam Thota</p>
                <p>With over 22 years of experience in the IT industry, I have had the opportunity to 
                    work on a wide range of projects and domains. From developing innovative solutions to
                    managing large-scale projects for Fortune 500 companies, I have tackled numerous 
                    challenges throughout my career.<br/><br/>
                    Over the years, I have also worked in various other 
                    domains, including healthcare, finance, and retail. This experience has given me a 
                    deep understanding of different industries and the unique challenges they face. I am 
                    always looking for ways to leverage technology to create innovative solutions that 
                    can drive business growth and improve the lives of end-users.<br/><br/>
                    As a leader, I am committed to fostering an environment of collaboration and 
                    innovation. I encourage my team members to share their ideas and perspectives, and I 
                    believe that diverse perspectives are essential to driving innovation. I am always 
                    looking for ways to challenge the status quo and push our team to new heights.<br/><br/>
                    Overall, my experience in the IT industry has equipped me with the skills and 
                    knowledge to handle complex projects and drive business growth. I am excited to 
                    continue leveraging my expertise to create innovative solutions that can make a 
                    positive impact in the world.<br/><br/>
                    As the CEO and Founder of Tradecafe, I have had the privilege of building something 
                    truly special from the ground up. Over the years, I have learned many valuable 
                    lessons about leadership, innovation, and resilience, and I have used these insights
                    to guide my team towards success.<br/><br/>
                    One of my core beliefs is that a company should be built on a foundation of purpose 
                    and values. That's why I have made it my mission to create a culture that is focused 
                    on making a positive impact.  As a leader, I am committed to fostering an environment
                    of collaboration and creativity. I encourage my team members to share their ideas and
                    perspectives, and I believe that diverse perspectives are essential to driving 
                    innovation. I am always looking for ways to challenge the status quo and push our 
                    company to new heights.<br/><br/>
                    Of course, building a successful company is not without its challenges. But I believe
                    that resilience is key to overcoming adversity. When faced with obstacles, I am always
                    looking for creative solutions and innovative approaches. I never give up on a problem,
                    and I encourage my team members to adopt the same mindset.<br/><br/>
                    Ultimately, my goal as CEO and Founder is to create a company that is not only 
                    successful but also makes a positive impact in the world. I believe that by staying 
                    true to our purpose and values, and by constantly pushing ourselves to innovate, we 
                    can achieve this vision and leave a lasting legacy.
                </p>
            </div>
        </div>
        <div className='member-container'>
            <div className='member-imgbx'>
                <img src={srinithota} alt='srinithota'/>
            </div>
            <div className='member-content'>
                <p>Srini Thota</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                    incididunt ut labore et dolore magna aliqua. Eget velit aliquet sagittis id 
                    consectetur purus ut faucibus pulvinar. Commodo quis imperdiet massa tincidunt. At 
                    in tellus integer feugiat. Tincidunt vitae semper quis lectus nulla at. Turpis 
                    cursus in hac habitasse platea dictumst quisque. Volutpat commodo sed egestas 
                    egestas fringilla phasellus faucibus scelerisque. Lorem donec massa sapien faucibus 
                    et molestie ac feugiat. Vitae turpis massa sed elementum tempus egestas sed. Purus 
                    faucibus ornare suspendisse sed nisi. Vitae semper quis lectus nulla at. Arcu odio 
                    ut sem nulla pharetra diam sit amet. Sit amet purus gravida quis blandit turpis 
                    cursus in.
                </p>
            </div>
        </div>
    </div>
  )
}

export default OurTeam