import React ,{useEffect} from 'react';
import '../../analyze/analyze.css';

function IpDashboard() {
  useEffect(() => {
    var divElement = document.getElementById('viz1677976733166'); 
    var vizElement = divElement.getElementsByTagName('object')[0]; 
        if ( divElement.offsetWidth > 800 ) { 
            vizElement.style.width='100%';
            vizElement.style.height=(divElement.offsetWidth*0.445)+'px';
        } else if ( divElement.offsetWidth > 500 ) { 
            vizElement.style.width='100%';
            vizElement.style.height=(divElement.offsetWidth*0.445)+'px';
        } else { 
            vizElement.style.width='100%';
            vizElement.style.height='850px';
        } 
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
    return () => {
      document.body.removeChild(scriptElement);
    }
  }, []);

  return (
      <div className='analyze-submenu-container' id='topScreen'>
          <div className='tableauPlaceholder' id='viz1677976733166' style={{position: 'relative'}}>
              <noscript>
                  <a href='#'>
                      <img alt='Indian Markets Index Dashboard ' src="https://public.tableau.com/static/images/In/IndianMarkets-indexDashboard/IndianMarketsIndexDashboard/1_rss.png" style={{border: 'none'}}/>
                  </a>
              </noscript>
              <object className='tableauViz' style={{display:'none'}}>
                <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F"/>
                <param name="embed_code_version" value="3"/>
                <param name="site_root" value=""/>
                <param name="name" value="IndianMarkets-indexDashboard/IndianMarketsIndexDashboard"/>
                <param name="tabs" value="no"/>
                <param name="toolbar" value="yes"/>
                <param name="static_image" value="https://public.tableau.com/static/images/In/IndianMarkets-indexDashboard/IndianMarketsIndexDashboard/1.png"/>
                <param name="animate_transition" value="yes"/>
                <param name="display_static_image" value="yes"/>
                <param name="display_spinner" value="yes"/>
                <param name="display_overlay" value="yes"/>
                <param name="display_count" value="yes"/>
                <param name="language" value="en-GB"/>
              </object>
          </div>
      </div>
  );
}

export default IpDashboard;