import React from 'react';
import './insight-submenu.css';
import comingsoonLogo from '../../../images/coming-soon-logo.png';
import simulation from '../../../images/simulation.png';

function Simulation() {
  return (
    <div className='insight-submenu-container' id='topScreen'>
        <div className='submenu-heading'>
            <img src={comingsoonLogo} alt='coming-soon-logo'/>
            <p>Simulation</p>
        </div>
        <div className='submneu-content'>
            <p>TradeCafe is proud to offer its customers a cutting-edge stock market simulator. Our 
                simulator is designed to help traders, both novice and experienced, to hone their skills 
                and develop their investment strategies in a safe and risk-free environment.<br/><br/> 
                TradeCafe's stock market simulator provides a highly realistic trading experience, with 
                &nbsp; real-time market data and access to a wide range of financial instruments, including 
                stocks, bonds, <img src={simulation} alt='simulation' className='simulation'/> options, futures, and currencies. Our simulator is powered by advanced 
                algorithms that simulate market behavior, enabling traders to experience the ups and 
                downs of the stock market without the financial risk.<br/><br/>  
                Traders can also use our simulator to track their progress and analyze their trading 
                performance, with access to a variety of tools and features that can help them improve 
                their trading skills. They can set up alerts to stay on top of market developments, use 
                advanced charting tools to identify trends, and access educational resources to deepen 
                their knowledge of the stock market.<br/><br/>  
                At TradeCafe, we believe that education is the key to success in the stock market, and 
                our simulator is an essential tool for any trader looking to build their skills and 
                knowledge. With our simulator, traders can develop their investment strategies, test out 
                different approaches, and gain the confidence they need to succeed in the real-world 
                stock market.<br/><br/> 
                In conclusion, TradeCafe's stock market simulator is a powerful tool for traders of all 
                levels, offering a safe and realistic environment for exploring the stock market and 
                building valuable trading skills. Whether you're a seasoned investor or a beginner, our 
                simulator is an essential part of your trading toolkit. So why wait? Sign up for 
                TradeCafe's stock market simulator today and start your journey to stock market success!
            </p>
        </div>
    </div>
  )
}

export default Simulation