import React from 'react';
import './market.css';
import MarketsToday from './marketsToday';
import EconomicCalender from './economicCalender';
import StocksToday from './stocksToday';
// import TcsStockMarket from './tcsStockMarket';
import TradingViewWidget from './tradingViewWidget';
import StockScreener from './stockScreener';
import HdfcFundamentals from './hdfcFundamentals';
import CryptoCurrency from './cryptoCurrency';

function Market() {
  return (
    <div className='market-container' id='topScreen'>
        <MarketsToday/>
        <TradingViewWidget/>
        <div className='marketSummary-worldMarket'>
            <EconomicCalender/>
            <StocksToday/>           
        </div>
        <StockScreener/>
        <div className='marketSummary-worldMarket'>
          <CryptoCurrency/>
        </div>
    </div>
  )
}

export default Market;