import React from 'react';
import './insights/insight-submenus/insight-submenu.css';
import comingsoonLogo from '../images/coming-soon-logo.png';
import portfolio from '../images/portfolio.png';

function PortfolioManagement() {
  return (
    <div className='insight-submenu-container' id='topScreen'>
        <div className='submenu-heading'>
            <img src={comingsoonLogo} alt='coming-soon-logo'/>
            <p>Portfolio Management</p>
        </div>
        <div className='submneu-content .offering-content'>
            <p>At Tradecafe, we believe that customization is the key to successful portfolio management.
                We understand that every client has different investment goals, risk tolerance, and 
                preferences, which is why we offer a highly personalized approach to portfolio management.
                <br/><br/>
                Our team of portfolio creators is committed to working closely with each client to 
                understand their unique needs and preferences. We believe that by taking the time to 
                understand our clients' investment goals, we can create a customized portfolio that is 
                tailored to their specific needs.
                <img src={portfolio} alt='portfolio' className='predict'/>
                <br/><br/>
                We know that risk tolerance is a crucial factor in creating a successful investment 
                strategy. We take into consideration each client's risk tolerance when creating their 
                portfolio, ensuring that it is aligned with their comfort level.<br/><br/>  
                Our team of experts will also work with each client to determine their preferences in 
                terms of sector allocations, stock picking strategies, and any other specific requests 
                they may have. We believe that by incorporating these preferences into their portfolio, 
                we can create a strategy that is uniquely tailored to their needs.<br/><br/>  
                We are committed to providing our clients with ongoing support and guidance. Our team of 
                portfolio creators will periodically review and adjust each client's portfolio to ensure 
                that it remains aligned with their investment objectives and risk tolerance.<br/><br/> 
                In summary, our portfolio management services are highly customizable and personalized. 
                We believe that by taking a personalized approach to portfolio management, we can help 
                our clients achieve their investment goals while minimizing their risks. Contact us 
                today to learn more about how we can help you create a customized stock portfolio that 
                is tailored to your specific needs and preferences.
            </p>
        </div>
    </div>
  )
}

export default PortfolioManagement