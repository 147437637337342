import React ,{useEffect} from 'react';
import '../../analyze/analyze.css';

function WmSnapshot() {
  useEffect(() => {
    var divElement = document.getElementById('viz1677977198479'); 
    var vizElement = divElement.getElementsByTagName('object')[0]; 
        if ( divElement.offsetWidth > 800 ) { 
            vizElement.style.width='100%';
            vizElement.style.height=(divElement.offsetWidth*0.75)+'px';
        } else if ( divElement.offsetWidth > 500 ) { 
            vizElement.style.width='100%';
            vizElement.style.height=(divElement.offsetWidth*0.75)+'px';
        } else { 
            vizElement.style.width='100%';
            vizElement.style.height='1500px';
        } 
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
    return () => {
      document.body.removeChild(scriptElement);
    }
  }, []);

  return (
      <div className='analyze-submenu-container' id='topScreen'>
          <div className='tableauPlaceholder' id='viz1677977198479' style={{position: 'relative'}}>
              <noscript>
                  <a href='#'>
                      <img alt='World Markets Dashboard' src="https://public.tableau.com/static/images/7X/7X93CJ4SH/1_rss.png" style={{border: 'none'}}/>
                  </a>
              </noscript>
              <object className='tableauViz' style={{display:'none'}}>
                <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F"/>
                <param name="embed_code_version" value="3"/>
                <param name="path" value="shared/7X93CJ4SH"/>
                <param name="toolbar" value="yes"/>
                <param name="static_image" value="https://public.tableau.com/static/images/7X/7X93CJ4SH/1.png"/>
                <param name="animate_transition" value="yes"/>
                <param name="display_static_image" value="yes"/>
                <param name="display_spinner" value="yes"/>
                <param name="display_overlay" value="yes"/>
                <param name="display_count" value="yes"/>
                <param name="language" value="en-GB"/>
              </object>
          </div>
      </div>
  );
}

export default WmSnapshot;